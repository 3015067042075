@import '../../../utilities/variable.scss';
.modal-container{
    display:none;
    position: fixed;
    background-color: rgba(0,0,0, 0.8);
    top:0;
    left:0;
    z-index: 1000;
    padding:15px 10%;
    // overflow: auto;
    height:100vh;
    width:100vw;
}
.modal-show{
    display: block;
}
#adsModalForm .formGroup{
    margin-top:60px;
}
.editModal{
    height: 100%;
    background-color: rgba(0,0,0, 1);
    overflow-y: auto;
    margin: 0 auto;
    border: 3px solid var(--main-yellow);
    border-radius: 5px;
    position: relative;
}
.editModal-dialog{
    max-width: 950px;
    overflow: auto;
    padding-bottom:45px;
    margin: 0 auto;
}
.adminHelpContainer{
    max-height:5000px;
}
.adminHelpContainer.hide{
    max-height: 0px;
}
.modalCloseBtn{
    position: absolute;
    top:0;
    right:0;
    margin-top:3px;
    margin-right: 3px;
}
@media (max-width: $phone){
    .modal-container{
        padding:15px 5px; 
    }
}