@import '../../utilities/variable.scss';

.Contact{
    padding: 20px 0 100px;
}

.Contact .contact-container{
    background: rgba(0, 0, 0, 0.45);
    border: 3px solid var(--red);
    outline: 3px solid white;
    width: 90%;
    max-width: 600px;
    margin:  60px auto;
    border-radius: 50px;
}
.Contact .contact-container h2{
    font-size: 3.6rem;
    text-shadow: -5px 0px 0px white;
    padding: 10px 30px;
    color: grey;
    // border-bottom: 2px solid var(--red);
    padding: 20px 0 20px;
    margin: 0  40px;
    
  }

.Contact .contact-section{
    margin:35px;
}
.Contact .contact-section .contact-info{
    display: flex;
    margin: 20px 0;
    justify-content: center;
    width: 100%;
}
.Contact .contact-section .contact-info:first-child{
    margin-left:32px;
}
.Contact .contact-section input{
    // width: 33%;
    flex-direction: flex-start;
    background-color: var(--black-transparent);
    border: 2px solid var(--red);
    border-radius: 10px;
    // outline-style: solid;
    // outline-color: white;
    // outline-width: 2px;
    padding: 7px 0 7px 8px;
    color: white;
    font-size: 1.3rem;
    // width: 200px;
    max-height: 46px;
    text-align: center;
}
.Contact .contact-section .label{
    margin: 0 30px 0 0;
    width: 185px;
    background: var(--black-transparent);
    padding: 10px 8px 5px;
    border-bottom : 2px solid var(--red);
    box-shadow: -1px -5px 15px var(--black-transparent);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-size: 1.2rem;
}


// copy button
// ==================================

.mail-text{
    // position: absolute;
    left: 85px;
    top: -4px;
    // background: white;
    
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        background-color: var(--black-transparent);
        border: 2px solid var(--red);
        font-size: .8rem;
        width: 60px;
        padding:  0 2px;
        height: 41px;
        margin-left: 10px;
        color: white;
        border-radius: 10px;
        margin-top:15px;
        margin-bottom:5px;
        transform: all 0.3s;
    }
    button:hover{
        cursor: pointer;
        background-color: darkgray;
    }
    #mailInput{
        font-size: 1.2rem;
        background: white;
        padding: 5px 10px;
        border-radius: 5px;
    }

    //Tooltip

    .tooltip {
        position: relative;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;
      }
      
      .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }

}

// phone styles 
// ========================

@media (max-width: $phone){
    .Contact .contact-section {
        .contact-info:first-child{
            margin-left:0;
        }
        input{
            font-size: 1.2rem;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .contact-info{
            flex-direction: column;
        }
        .label{
            margin: 0;
            width: 100%;
        }
    
    }
}

#adminButtonSection{
    color: white;
    background-color: rgb(80, 80, 80);
    position:absolute;
    bottom:-75px;
    right:0px;
    transition: all 0.3s;
    border: 2px solid white;
    padding: 5px 10px;
    border-radius: 5px;
}
#adminButtonSection:hover{
    cursor: pointer;
    background-color: rgba(255,255,255,0.1);
}
#adminButtonSection:active{
background-color: black;
color:rgba(255,255,255,0.2);
}

.social-icons{
    display: flex;
    justify-content: space-between;
    padding:5%;
    padding-top:0;
}
.social-icons .image{
    background-size: contain;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    overflow: hidden;
    transition: all 0.3s;
}
.social-icons .image div{
    width: 60px;
    height: 60px;
    transition: all 0.3s;
}
.social-icons .image:hover{
    box-shadow: 0px 0px 15px 8px rgba(0,0,0, .475);
}
.social-icons .image div:hover{
    background: rgba(255,255,255, .275);
    cursor: pointer;
}
.social-icons .image div:active{
    background: rgba(0,0,0, .475);
}
/* Social buttons */
.social-icons .image.facebook{
    background-image: url('../../images/social/facebook.png');
}
.social-icons .image.instagram{
    background-image: url('../../images/social/instagram.png');
}
.social-icons .image.twitter{
    background-image: url('../../images/social/twitter.png');
}

// .sub-input{
//     display: flex;
//     justify-content: space-between;
// }
// .sub-input input{
//     background-color: var(--black-transparent);
//     border: 2px solid var(--red);
//     outline-style: solid;
//     outline-color: white;
//     outline-width: 2px;
//     padding: 10px;
//     color: white;
//     font-size: .9rem;
//     width: 200px;
// }
// .sub-input button{
//     padding: 5px 0;
//     box-shadow: 0 0 20px black;
//     font-size: 1rem;
//     border-radius: 6px;
//     width: 100px;
// }

// Meda queries phone
//

@media (max-width: $phone){
    
}