/* import FacebookIcon from '../../images/social/facebook.png';
import instaIcon from '../../images/social/instagram.png';
import twitterIcon from '../../images/social/twitter.png'; */
@import "../../utilities/variable.scss";

.Footer{
    /* height: 440px; */
    margin: 0;
    background-color: var(--black-transparent);
    color: white;
    display:flex;
    flex-direction: column;
    justify-self: flex-end;

}
.Footer .footer-section{
    background-color: none;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    /* padding: 20% 20%; */


}
.Footer .footer-section .sub-section{
    width: 50%;
    margin: 40px 0;
}

.Footer .footer-section .sub-section .sub-container{
    text-align: left;
    margin: 0 auto;
    width: 337px;

}
.Footer .social-icons{
    display: flex;
    justify-content: space-between;
}
.Footer .social-icons .image{
    background-size: contain;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    overflow: hidden;
    transition: all 0.3s;
}
.Footer .social-icons .image div{
    width: 60px;
    height: 60px;
    transition: all 0.3s;
}
.Footer .social-icons .image:hover{
    box-shadow: 0px 0px 15px 8px rgba(0,0,0, .475);
}
.Footer .social-icons .image div:hover{
    background: rgba(255,255,255, .275);
    cursor: pointer;
}
.Footer .social-icons .image div:active{
    background: rgba(0,0,0, .475);
}
/* Social buttons */
.Footer .social-icons .image.facebook{
    background-image: url('../../images/social/facebook.png');
}
.Footer .social-icons .image.instagram{
    background-image: url('../../images/social/instagram.png');
}
.Footer .social-icons .image.twitter{
    background-image: url('../../images/social/twitter.png');
}

.Footer .sub-input{
    display: flex;
    justify-content: space-between;
}
.Footer .sub-input input{
    background-color: var(--black-transparent);
    border: 2px solid var(--red);
    outline-style: solid;
    outline-color: white;
    outline-width: 2px;
    padding: 10px;
    color: white;
    font-size: .9rem;
    width: 200px;
}
.Footer .sub-input button{
    padding: 5px 0;
    box-shadow: 0 0 20px black;
    font-size: 1rem;
    border-radius: 6px;
    width: 100px;
}

// Meda queries phone
//

.Footer .footer-section .copyright{
    margin-top:auto;
    margin-bottom: 60px;
}
.copyright{
    font-size: .8rem;
}
@media (max-width: $phone){
    .Footer .footer-section{
        flex-direction: column-reverse;
        margin: 0 auto;
        .info{
            margin-bottom: 0;
        }
    }
    .Footer .footer-section .sub-section {
        width: 100%;
        .sub-container{
            width:100%;
            padding: 0 7px;
        }
    }
    
}