@import "utilities/variable.scss";
*{
  box-sizing: border-box; 
}
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/AR.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-lightyellow:  #C15E94;
  --main-yellow: #C15E94;
  /* --main-lightyellow:  rgb(255, 250, 104);
  --main-yellow: rgb(250, 233, 140);  */
  --main-darkyellow: #C15E94;

  --blue: rgb(54,163,250);

  --pink: #C16794;
  --pink-light: #C16794;
  /* --red: rgb(255,102,102); */
  --red:   #4F1150;
  /* --red :  rgb(219,13,64); */
  --red-dark: #4F1150;
  --red-dark: #C16794;
  /* --red: rgb(24,24,24); */
  --light-black: rgb(24,24,24);
  --black: white;
  --black-transparent: rgba(0, 0, 0, 0.73);
  --black-text: black;

  --main-padding: 15px;  

  --tablet-point:  1005px;
  --phone-point:  705px;

  --event-background : black;
}


.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
  min-height: 100vh;
  /* height: 100vh; */
  background-image: url('./images/Softball-big.jpg');
  background-image: url('./images/softball-field-background-pg.jpg');
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container{
  padding: 55px 10px 35px;
  padding-top: 0;
  height: 91%;
  /* background-color: var(--red); */
  /* background-image: url('./images/behind-the-plate.jpg');
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center; */
}

.container-after{
  background: var(--black-transparent);
  height: 100%; 
  padding-top: .1px;  
  color: white;
  position: relative
}
@media only screen and (max-width: 770px) {
  .container{
    padding: 0px 0 35px;
  }
}
.button-main{
  // font-family: 'Luckiest Guy', cursive;
  background: var(--main-yellow);
  border: 4px solid var(--red);
  /* border-style: groove; */
  padding: 10px 75px 12px;
  font-size: 1.4rem;
  color: var(--black);
  font-weight: bold;
  border-radius: 32px;
  transition: all 0.3s;
  text-decoration: none;
}
.button-main:focus{
  outline: none;
}
.button-main:hover{
  color: var(--main-yellow);
  border-color:  var(--red);
  background-color: rgb(59, 59, 59);
  box-shadow: 0px 0px 15px 8px rgba(0,0,0, .575);
  cursor: pointer;
}
.button-main:active{
  background:   var(--main-darkyellow);
  color:  white;
}
.quote{
  padding-bottom: 30px;
}
#SaveScreen{
  display: none;
  position: fixed;
  top:0;
  left:0;
  height:100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.75);
  color:white;
  text-align: center;
  z-index: 1000;
}
#SaveScreen span{
  display: block;
  font-size:2.8rem;
  margin-top: calc(50vh - 50px);
}
#SaveScreen.show{
  display: block;
}
.title-space{
  min-height: 30px;
  min-width: 1px;
  background-color: transparent;
  color: transparent;
}
