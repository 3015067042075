.page{
    padding: 20px 0 100px;
    min-height: 40vh;
}
.edit-tabs{
    display:flex;
justify-content: center;
align-items: center;
border-radius: 30px;
overflow: hidden;
}
.tab{
    text-decoration: none;
    background: var(--main-yellow);
    color: var(--black);
    font-size: 1.2rem;
    padding: 6px 20px 8px;
    display: inline-block;
    border-right: 2px dashed var(--red);
    transition: all 0.3s;
}
.tab:hover{
    background: var(--main-lightyellow);
    color: var(--red);
    cursor: pointer;
}
.tab:first-child{
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.tab:last-child{
    border-right:none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.tab-active{
    background: var(--main-lightyellow);
    color: var(--red);

}
.edit-section-body{
    display:none;
    padding: 5px 10px;
}
.section-active{
    display: block;
}
@media (max-width: 600px){
    .edit-tabs{
       flex-direction:column;
       .tab{
        border-radius: 0px;
        width:100%;
        margin-bottom: 2px;
       }
       .tab:last-child{
        margin-bottom: 0;
       }
    }
}