@import '../../utilities/variable.scss';

.admin-modal.modal-container{
    background-color: rgba(0,0,0, 0.9);
}

.modal-show{
    display: block;
}
.admin-modal {
.editModal{
    height: 215px;
    background-color: rgba(0,0,0, 1);
    overflow-y: auto;
    margin: 0 auto;
    border: 3px solid var(--main-yellow);
    border-radius: 5px;
    position: relative;
    margin-top:calc(50vh - 200px);
    max-width:900px;
}
.editModal-dialog{
    max-width: 950px;
    overflow: auto;
    padding-bottom:45px;
    // margin: 0 auto;
}
.adminHelpContainer{
    max-height:5000px;
}
.adminHelpContainer.hide{
    max-height: 0px;
}
.modalCloseBtn{
    position: absolute;
    top:0;
    right:0;
    margin-top:3px;
    margin-right: 3px;
}
}
@media (max-width: $phone){
    .modal-container{
        padding:15px 5px; 
    }
}