.imgList{
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 60px;
    position: relative;
    padding-right:160px;
}
.adImageDiv{
    height:250px;
    width:250px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
    img{
        width:100%;
        height:100%;
    }
}
.ad-link{
    margin:0 auto;
    margin-top:5px;
    padding-right: 15px;
    padding-left:15px;
    width:50%;

}
.imgList.hide{
    display: none;
}
.ad-link-div{
    display: flex;
    justify-content: center;
    width:100%;
    top: calc(50% - 70px);
    left:0;
    margin-top: -60px;
    margin-bottom: 30px;
}
@media (max-width: 1350px){
    .ad-link-div{
    position: absolute;
    margin:0;
    }
}
@media (max-width: 800px){
    .imgList{
        padding: 0 10px;
    }
    .adImageDiv{
        height:50%;
        width:50%;
        
        img{
            width:100%;
            height:100%;
        }
    }
    .ad-link{
        padding-left:3px;
        padding-right:3px;
        width: calc(100vw - 6px);

    }
}