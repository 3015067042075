@import '../../../utilities/variable.scss';
.formGroup{
    display: flex;
    flex-direction: column;
    max-width:100%;
}
.formGroup p{
    font-size: 0.8rem;
}
.formGroup label{
    margin-top:10px;
    margin-bottom: 5px;
}
textarea{
    resize: vertical;
}
textarea, input{
    background-color: rgba(255,255,255,0.2);
    color:white;
    padding: 15px;
    margin: 15px 2%;
    border-radius: 5px;
}
label{
    text-align: left;
    margin-left:2%;
}
.adminCard{
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 3px solid white;
    padding:5px 15px;
    // border-radius: 10px;
    // margin-bottom:5px;
    border-top:none;
    width:100%;
}
.adsCardGroup .adminCard{
    justify-content: space-around;
}
.adminCard:nth-child(2), .edit-section-no-add .adminCard:first-child{
    border-top: 3px solid white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.adminCard:last-of-type, .adminCard:last-child{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.cardDelete{
    margin-top:20px;
    height:40px;
    width:50px;
    justify-self: flex-end;
}
.cardDelete img{
    height:34px;
    width:34px;
    transition: all 0.2s;
}
.cardDelete:hover{
    cursor: pointer;
}
.cardDelete:hover img{
 height:40px;
 width:40px;   
}
.cardDelete:active img{
    height:35px;
    width:35px; 
    opacity: 0.8;  
   }
   .preveiewGroup{
    min-width:150px;
    padding: 0 5px;
   }
   .preveiwImage{
    height:80px;
    width:auto;
    margin: 0 auto;
   }
   .admin-btn-group{
    align-self: center;
   }
   .adminFormButton{
    background-color: rgb(80, 80, 80);
    color:white;
    font-size:1.05rem;
    padding:10px 25px;
    outline: none;
    border: 2px solid white;
    transition: all 0.2s;
    border-radius: 20px;
    margin-top:15px;
    margin-right:10px;
    font-weight: 600;
   }
   .submitButton{
    min-width:110px;
    background-color: rgb(1, 113, 66);
   }
   .submitFormButton{
    font-size: 18px;
      padding: 15px 30px;
      border-radius: 20px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-top:15px;
  }
  
     .admin-btn-group .adminFormButton{
        margin-top:0px;
     }
   .adminFormButton.del-btn{
    background-color: rgba(250,0,0,0.5)
   }
   .adminFormButton:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,1);
    border-color:lightgray;
   }
   .adminFormButton.del-btn:hover{
    background-color: rgba(200,0,0,0.95)
   }
   .adminFormButton:active{
    background-color: black;
    color:rgba(255,255,255,0.2);
   }
   .adminFormButton.del-btn:active{
    background-color: rgba(160,0,0,0.95);
   }
   .admin-modal-action{
    display:inline;
    margin-left:35px;
    margin-top:0;
   }
   .modal-footer{
    display: flex;
    justify-content: center;
    flex-direction: row;
   }
    // modal help section stuff
    #adminUrlHelp{
        overflow: hidden;
    }
    #adminUrlHelp p{
        text-align: left;
        margin-left:10%;
        margin-right: 10%;
    }
    #adminUrlHelp a{
        color:white;
        
    }
    #adminUrlHelp a:hover{
        color:var(--main-lightyellow);
    }
    #adminUrlHelp img{
        width:80%;
    }
    #adminUrlHelp .admin-modal-action{
        float:left;
        margin-left:10%;
    }
    .radio-parent{
        display: flex;
        margin-left:3rem;
    }
    .radio-group{
        display:flex;
        margin-left:3rem;
    }
    .radio-group input{
        margin-right:1rem;
    }
    .hide-form-group{
        display: none;
    }
    .evenGroup{
        width:20%;
    }
    .evenGroup-small{
        width:10%;
    }
    .evenGroup-big{
        width:30%;
    }
    .evenGroup{
        text-wrap: balance !important;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: left;
    }
    .evenGroup .button-main{
        min-width:260px;
        max-width:310px;
    }
    .admin-image{
        max-width: 160px;
        max-height: 160px;
        aspect-ratio: 1/1;
        margin-bottom: 0;
    }

    @media (max-width: $phone){
        .evenGroup{
            width:90%;
        }
        .adminCard{
            flex-direction: column;
            display:flex;
            align-items: flex-start;
            justify-content: space-between;
            border: 3px solid white;
            padding:5px 15px;
            // border-radius: 10px;
            // margin-bottom:5px;
            border-top:none;
            width:100%;
        }
        .formGroup p{
            text-wrap: balance !important;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        .formGroup label{
            text-decoration: underline;
            
        }
        .adminFormButton.del-btn{
            margin-top:25px;
        }
    }

    .evenGroup .clinic-link{
        background: var(--main-yellow);
    border: 4px solid var(--red);
    border-radius: 30px;
    box-shadow: 0 0 25px -10px #fff;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 15px auto 25px;
    padding: 15px 14px 16px;
    height:65px;
            min-width:285px;
            font-size:20px !important;
            width:auto;
        transition: all 0.3s;
        background: var(--main-yellow);
        border: 4px solid var(--red);
        width:auto;
    font-size:20px;
            margin-left: 15px;
        margin-right: 15px;
        color:white;
        max-width:420px;
        text-decoration: none;
        text-align: center;
    }
    
    .clinic-link:hover{
        color: var(--main-yellow);
        border-color:  var(--red);
        background-color: rgb(59, 59, 59);
    }