@import '../../utilities/variable.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.Events{
    position: relative;
    
    .eImage{
        width: 740px;
        margin: 25px 0 ;
        border-radius: 20px;
    }
    h1{
        font-size: 2.1rem;
        text-shadow: -6px 0px 0px black;
        padding: 50px 30px 10px;
        margin: 0;
        color:white;
        border-top: 2px solid var(--red-dark);
      }
      p{
          font-size:.4rem;
          position: absolute;
          left: -62px;
          bottom:-362px;
      }

    .event-list{
        display:flex;
        padding-bottom: 55px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        h1{
            width:100%;
        }
        .event-row{
            width: 30%;
            margin-top: auto;
        }
        .event-item{
            
            border-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.55);
            border: 3px solid rgba(0, 0, 0, 0.55);
            // width: 75%;
            width: 100%;
            max-width: 830px;
            // min-width: 430px;
            margin: 0px auto 0px;
            padding-bottom: 45px;
            // height: 500px;
            iframe{
                width: 100%;
                // height: 1060px;
                aspect-ratio: 1/1.3;
                pointer-events:none;
            }
            h3{
                font-size: 1.6rem;
                text-shadow: -4px 0px 0px var(--red);
                color: var(--main-yellow);
            }
            .event-body{
                white-space: pre-wrap;
                line-height: 1.8rem;
                font-size: 1.05rem;
                
            }
        }
        .pdf-link{
            display: flex;
            width: 100%;
            text-align: center;
            justify-content: center;
            margin: 45px auto 0;
            font-size: 1.4rem;
            cursor:text;
            text-decoration: none;
            max-width: 420px;
            padding: 10px 5px 11px;
           
            // color: var(--red);
            // color:var(--main-yellow);
            color:white;
            
        }
    }
    .link-btn-group{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .link-btn-group .clinic-link{
        height:65px;
        min-width:285px;
        font-size:20px;
        width:auto;
    }
}
.Events .event-list .clinic-link{
    transition: all 0.3s;
    background: var(--main-yellow);
    border: 4px solid var(--red);
}
.Events .event-list .event-link{
    cursor:pointer;
    border-radius: 30px;
    transition: all 0.3s;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.Events .event-list .event-link:hover{
    color: var(--main-yellow);
    border-color:  var(--red);
    background-color: rgb(59, 59, 59);
}
.Events .event-list .clinic-link:hover{
    color: var(--main-yellow);
    border-color:  var(--red);
    background-color: rgb(59, 59, 59);
}
.Events .event-list .link-description{
    color: white;
    margin-top:20px;
}
.linkParent{
    // padding:30px;
    display: flex;

}
.linkParent img{
    width:100px;
    height: 100px;
}
.links-list{
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 0 !important;
}
.links-section{
    width: 50%;
    min-width: 320px;
    max-width:400px;
    display: flex; 
    flex-direction: column;
    justify-content: flex-end;
}
@media (max-width: 1100px){
    .Events p{
        display: none;
    }
    .Events{
        .event-list{
            .event-row{
                width: 48%;
            }
        }
    }
}
@media (max-width: ($phone + 125px)){
    .Events{
        
        h1{
            padding-left:2px;
            padding-right: 2px;
            font-size: 2.1rem;
        }
        .event-list{
            display:flex;
            .event-row{
                width: calc(100% - 20px);
                margin-right: 10px;
                margin-left:10px;
            }
            .event-item{
                // width: 97%;
                border: 3px solid var(--main-lightyellow);
                border-top:none;
                min-width: 200px;
                aspect-ratio : 1 / 1.25;
                margin-top:0;
                
            }
            .pdf-link{
                cursor:pointer;
                font-size: 1.3rem;
                font-weight: bold;
                border: 3px solid var(--main-lightyellow);
                
                background-color: var(--event-background);
                border-radius: 30px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-bottom: none;
                padding: 15px 14px 16px;
                margin-top: 30px;
                box-shadow: 0px 0px 25px -10px white;
            }
        }
        .eImage{
            display:none
        }
      }
    }
    .Events .event-list .clinic-link{
    cursor:pointer;
    font-size: 1.3rem;
    font-weight: bold;
    // border: 3px solid var(--main-lightyellow);
    // background-color: var(--event-background);
    background: var(--main-yellow);
    border: 4px solid var(--red);
    border-radius: 30px;
    padding: 15px 14px 16px;
    margin: 15px auto 25px;
    width:85%;
    box-shadow: 0px 0px 25px -10px white;

}
.Events .link-btn-group .clinic-link{
    width:auto;
font-size:20px;
        margin-left: 15px;
    margin-right: 15px;
}
.event-item img{
    width:100%;
}
.event-item-img{
    aspect-ratio: 6/4.05 !important;
}
