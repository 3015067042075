/* :root {
  --main-lightyellow:  rgb(250, 233, 140);
  --main-yellow: rgb(255, 250, 104);  
  --main-darkyellow: rgb(136, 126, 73);

  --main-pink: rgb(255,102,102);
  --main-lightpink: rgb(226,138,126);
  --red: red;

  --main-padding: 15px;  
} */

.Navbar{
    background: transparent;
    min-height: 9%;
    padding: 10px 5px 20px;
    /* display: flex; */
    // font-family: 'Luckiest Guy', cursive;
    font-family: "Open Sans", sans-serif;
    position: relative;
}
#navBarLogo{
position:absolute;
left:15px;
top:15px;
}
#navBarLogo img{
    width:55px;
    height: 55px;
}
.Navbar .Navbar-items{
    background: var(--main-yellow);
    border: 4px solid var(--red);
    box-shadow: 0 0 20px white;
    width: auto;
    margin: 0 auto;
    margin-top: 12px;
    padding: 0;
    border-radius: 35px;
    display: inline-block;
    overflow: hidden;
}
.Navbar .Navbar-items a{
    text-decoration: none;
    background: var(--main-yellow);
    color: var(--black);
    font-size: 1.2rem;
    padding: 6px 20px 8px;
    display: inline-block;
    border-right: 2px dashed var(--red);
    transition: all 0.3s;
}
.Navbar .Navbar-items a::selection {
    color: none;
    background: none;
}

.Navbar .Navbar-items a:hover{
    background: var(--main-lightyellow);
    color: var(--red);

}
.Navbar .Navbar-items a:active{
    background:   var(--main-darkyellow);
    color:  white;
}
.Navbar .Navbar-items a:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.Navbar .Navbar-items a:last-child{
    border-right:   none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.Navbar .Navbar-items .bar-nav-current{
    background: var(--main-lightyellow);
    color: var(--red);
}
.Navbar .Navbar-items .events span{
    font-size: .8rem;
    // font-family: "Lucida Console", Courier, monospace;
    font-family: "Open Sans", sans-serif;
    text-decoration: underline;
}
.Navbar .Navbar-items .events span::selection{
    color: none;
    background: none;
}
.bar-nav-item{
    // border-radius: 30px;
}
// Tablet styles
// ===============/

@media (max-width: 1190px){
    .Navbar{
        min-height: 100px;
        .Navbar-items{
            display: none;
            background: red
        }
    }
}
.menu-div{
    position: fixed;
    left: 0;
    top: 0px;
    z-index: 200;
    // width: 100vw;
}

